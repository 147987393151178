import { graphql, useStaticQuery } from "gatsby"

const ALL_FLEXMED_IMAGES = graphql`
  query {
    medgrocer: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
      childImageSharp {
        fluid(maxHeight: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heart: file(relativePath: { eq: "maersk_hero long.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 2000
          duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
          toFormat: PNG
          quality: 90
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    processTriangleGrey: file(
      relativePath: { eq: "process_triangle--grey.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    flexmedHowToClick: file(
      relativePath: { eq: "flexmed/flexmed_upload-click--teal.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedHowToSubmit: file(
      relativePath: { eq: "flexmed/flexmed_submit-physical--teal.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedHowToConfirm: file(
      relativePath: { eq: "flexmed/flexmed_receive-confirm--teal.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedCoverageMedicine: file(
      relativePath: { eq: "coverage_medicines.png" }
    ) {
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedCoverageOptical: file(relativePath: { eq: "coverage_optical.png" }) {
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedCoverageDental: file(relativePath: { eq: "coverage_dental.png" }) {
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedCoverageWellness: file(
      relativePath: { eq: "coverage_wellness.png" }
    ) {
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedRequiredReceipt: file(
      relativePath: { eq: "required_receipt--teal.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedRequiredOnline: file(
      relativePath: { eq: "required_online--teal.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedRequiredDocuments: file(
      relativePath: { eq: "required_documents--teal.png" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    flexmedRequiredRx: file(relativePath: { eq: "required_rx--teal.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const useFlexMedImages = () => {
  const data = useStaticQuery(ALL_FLEXMED_IMAGES)
  return data
}
