import React, { useEffect } from "react"
import moment from "moment"
import { navigate } from "gatsby"
import Img from "gatsby-image"

import Container from "../Layout/Container"
import Hero from "../Layout/Hero"
import Button from "../Elements/Button"
import { useFlexMedImages } from "./hooks/useFlexMedImages"
import { animate } from "services/animations"

import styles from "./utils/flexmed.module.scss"
import { GATSBY_WEBSITE_SHUTDOWN_DATE } from "gatsby-env-variables"

const FlexMedBanner = () => {
  const data = useFlexMedImages()
  const medgrocer = data.medgrocer.childImageSharp.fluid

  useEffect(() => {
    animate({
      x: -100,
      type: "from",
      target: ".fade-from-left",
      duration: 1,
      stagger: 0.2,
    })
  }, [])

  if (moment(GATSBY_WEBSITE_SHUTDOWN_DATE).isSameOrBefore(moment(), "day"))
    return (
      <Hero
        color="light"
        size="fullheight"
        backgroundImage={data.heart.childImageSharp.fluid.src}
      >
        <Container isCentered desktop={10} fullhd={9}>
          <figure className={styles["logo"]}>
            <Img fluid={medgrocer} alt="MedGrocer" />
          </figure>
          <h1 className="title is-size-1-desktop is-size-3-mobile has-text-primary mt-1">
            The FlexMed program has now ended. The 2023 FlexMed Program has now
            ended.
          </h1>
          <h3 className="subtitle is-size-3-desktop is-size-5-mobile">
            Thank you for letting us serve you.
          </h3>
        </Container>
      </Hero>
    )

  return (
    <Hero
      color="light"
      size="medium"
      backgroundImage={data.heart.childImageSharp.fluid.src}
    >
      <Container isCentered>
        <Container desktop={8}>
          <h1 className="fade-from-left mt-0">Wellness made easy</h1>
          <h3 className="fade-from-left has-text-weight-bold has-text-primary">
            FlexMed
          </h3>
          <p className="fade-from-left is-size-5">
            Claim reimbursements for up to Php 10K worth of health and wellness
            benefits
          </p>
          <Button
            color="primary"
            size="medium"
            className="fade-from-left mt-2"
            onClick={() => navigate("/flexmed/mechanics")}
          >
            Upload Receipt
          </Button>
        </Container>
      </Container>
    </Hero>
  )
}

export default FlexMedBanner
